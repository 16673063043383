.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 2rem;
  background: linear-gradient(to right, #06265a, #48df84);
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo img {
  width: 30px;
  margin-right: 0.5rem;
}

.navbar-link {
  text-decoration: none;
  color: #fff;
  transition: color 0.3s ease;
  font-weight: 500;
  font-size: 1.2rem;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.navbar-link:hover {
  color: var(--ifm-link-hover-color);
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.brand-name {
  font-size: 1.5rem;
  font-weight: 700;
}

.navbar ul {
  display: flex;
  list-style: none;
}

.navbar-item {
  margin-left: 2rem;
  font-size: 1rem;
  font-weight: 500;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  width: 25px;
}

.bar {
  height: 3px;
  width: 100%;
  background-color: #fff;
  margin: 4px 0;
  transition: all 0.3s ease;
}

.bar.open {
  background-color: #ffdd57;
}

@media (max-width: 768px) {
  .navbar-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out;
  }

  .navbar-menu.active {
    transform: translateX(0);
  }

  .navbar ul {
    flex-direction: column;
  }

  .navbar-item {
    margin: 2rem 0;
    font-size: 1.5rem;
  }

  .navbar-item {
    margin: 1rem 0;
    font-size: 1.5rem;
  }

  .hamburger {
    display: flex;
  }
}

.navbar.scrolled {
  background-color: var(--ifm-dark-bg-color);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1024px) {
  .navbar-item {
    margin-left: 1rem;
  }
}

@media (max-width: 480px) {
  .brand-name {
    font-size: 1.2rem;
  }
}
