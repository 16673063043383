.contact-page {
  padding: 50px 0;
  background-color: var(--ifm-light-bg-color);
  color: var(--ifm-text-color);
}

.contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
}

.contact-form, .contact-map {
  flex: 1;
  margin: 20px;
}

.contact-map h2 {
  color: var(--ifm-text-color);
}

.contact-form {
  background-color: var(--ifm-card-bg-color);
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  color: var(--ifm-text-color-light);
}

.contact-form h2 {
  color: var(--ifm-link-color);
}

.contact-form p {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
}

input, textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid var(--ifm-btn-bg-color);
  border-radius: 5px;
  background-color: #fff;
  color: var(--ifm-text-color);
}

input::placeholder, textarea::placeholder {
  color: var(--ifm-text-color-light);
}

input:focus, textarea:focus {
  border-color: var(--ifm-link-color);
  outline: none;
}

textarea {
  resize: vertical;
}

.btn-submit {
  padding: 12px 25px;
  background-color: var(--ifm-btn-bg-color);
  border: none;
  border-radius: 5px;
  color: var(--ifm-btn-text-color);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-submit:hover {
  background-color: var(--ifm-btn-hover-bg-color);
}

.contact-map iframe {
  border-radius: 8px;
}

@media (max-width: 768px) {
  .contact-form, .contact-map {
    width: 100%;
    margin-bottom: 30px;
  }

  .contact-container {
    flex-direction: column;
    align-items: center;
  }
}
