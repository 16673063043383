.admin-users-section {
  padding: 1rem 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-users-section .container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.admin-users-section h1 {
  text-align: center;
  color: #333;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.users-table thead {
  background-color: var(--ifm-dark-bg-color);
  color: #fff;
  text-align: left;
}

.users-table th,
.users-table td {
  padding: 1rem;
  border-bottom: 1px solid #ddd;
}

.users-table th {
  font-weight: bold;
  font-size: 1.2rem;
}

.users-table tr:last-child td {
  border-bottom: none;
}

.edit-link,
.delete-btn {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.edit-link {
  background-color: #17a2b8;
}

.edit-link:hover {
  background-color: #138496;
}

.delete-btn {
  background-color: #dc3545;
  border: none;
  cursor: pointer;
}

.delete-btn:hover {
  background-color: #c82333;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  .users-table th,
  .users-table td {
    padding: 0.75rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .users-table,
  .users-table thead,
  .users-table tbody,
  .users-table th,
  .users-table td,
  .users-table tr {
    display: block;
    width: 100%;
  }

  .users-table thead {
    display: none;
  }

  .users-table tr {
    margin-bottom: 1rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 1rem;
  }

  .users-table td {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    font-size: 0.9rem;
  }

  .users-table td::before {
    content: attr(data-label);
    font-weight: bold;
    color: #333;
  }
}