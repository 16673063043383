.courses-page {
  padding: 2rem;
  background-color: #f8f8f8;
}

.courses-header {
  text-align: center;
  margin-bottom: 3rem;
}

.courses-header h1 {
  font-size: 3rem;
  color: #333;
}

.courses-header p {
  font-size: 1.2rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
}

.courses-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.course-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.course-card:hover {
  transform: translateY(-10px);
}

.course-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.course-details {
  padding: 1.5rem;
}

.course-details h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.course-details p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
}

.course-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.course-info span {
  font-size: 0.9rem;
  font-weight: bold;
  color: #3498db;
}

.enroll-btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.enroll-btn:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {
  .courses-header h1 {
    font-size: 2.5rem;
  }

  .courses-header p {
    font-size: 1rem;
  }
}
