.services-section {
  padding: 4rem 2rem;
  background-color: #f7f8fa;
  text-align: center;
}

.section-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.section-description {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 3rem;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.service-card {
  background-color: #fff;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
}

.service-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
}

.service-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.service-description {
  font-size: 1rem;
  color: #666;
}

@media (max-width: 768px) {
  .service-card {
    padding: 1.5rem;
  }

  .service-title {
    font-size: 1.4rem;
  }

  .service-description {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .service-card {
    padding: 1rem;
  }

  .service-title {
    font-size: 1.2rem;
  }

  .service-description {
    font-size: 0.85rem;
  }
}