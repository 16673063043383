.admin-panel {
  display: flex;
  min-height: 100vh;
  background-color: #f4f7fb;
  padding-top: 50px;
}

.sidebar {
  width: 250px;
  background-color: #2c3e50;
  color: #fff;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
}

.sidebar nav {
  width: 100%;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar-link {
  display: flex;
  align-items: center;
  padding: 1rem;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.sidebar-link:hover {
  background-color: #34495e;
}

.icon {
  margin-right: 1rem;
  font-size: 1.2rem;
}

.content {
  margin-left: 250px;
  padding: 0;
  flex-grow: 1;
}

.admin-header {
  text-align: center;
  padding: 1.5rem 0;
  background-color: var(--ifm-dark-bg-color);
  color: #fff;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.admin-header h1 {
  font-size: 2rem;
}

.admin-info p {
  margin-top: 0.5rem;
  font-size: 1.1rem;
}

.main-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .sidebar {
    width: 200px;
  }

  .sidebar-link {
    font-size: 0.9rem;
  }

  .icon {
    font-size: 1rem;
  }

  .content {
    margin-left: 200px;
  }

  .admin-header h1 {
    font-size: 1.8rem;
  }

  .admin-info p {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .sidebar {
    width: 100%;
    position: static;
    height: auto;
  }

  .sidebar-link {
    justify-content: center;
    padding: 0.75rem;
  }

  .content {
    margin-left: 0;
    padding: 0;
  }

  .admin-header h1 {
    font-size: 1.5rem;
  }

  .admin-info p {
    font-size: 0.9rem;
  }

  .main-content {
    padding: 1rem;
  }
}
