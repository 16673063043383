.admin-update {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f4f7fb;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

admin-update h1 {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 2rem;
}

.update-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  font-size: 1rem;
  color: #34495e;
  margin-bottom: 0.5rem;
  display: block;
}

.form-group input {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.form-group input:focus {
  outline: none;
  border-color: #007bff;
}

.btn-update {
  background-color: #007bff;
  color: #fff;
  padding: 0.75rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-update:hover {
  background-color: #0056b3;
}

/* Responsive Design */

@media (max-width: 768px) {
  .admin-update {
    padding: 1.5rem;
  }

  .form-group input {
    font-size: 0.9rem;
  }

  .btn-update {
    font-size: 1rem;
    padding: 0.65rem;
  }
}

@media (max-width: 576px) {
  .admin-update {
    padding: 1rem;
  }

  .form-group input {
    font-size: 0.85rem;
    padding: 0.6rem;
  }

  .btn-update {
    font-size: 0.9rem;
    padding: 0.6rem;
  }
}
