.dashboard-container {
  margin-top: 50px;
  display: flex;
  min-height: 100vh;
}

.sidebar {
  width: 250px;
  background-color: #2c3e50;
  padding: 20px;
  color: #ecf0f1;
}

.sidebar-title {
  /* text-align: center; */
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #49df84;
}

.sidebar-nav ul {
  list-style: none;
  padding: 0;
}

.sidebar-nav ul li {
  margin-bottom: 15px;
}

.sidebar-nav ul li a {
  color: #ecf0f1;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.sidebar-nav ul li a:hover {
  color: #3498db;
}

.nav-text {
  margin-left: 10px;
}

/* Main Content */
.dashboard-main {
  margin-left: 250px;
  flex-grow: 1;
  padding: 20px;
  background-color: #f4f7fb;
}

/* Header */
.dashboard-header {
  text-align: center;
  margin-bottom: 20px;
}

.dashboard-header h1 {
  color: #34495e;
}

/* Stats Cards */
.dashboard-cards {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  margin-right: 20px;
  text-align: center;
}

.card:last-child {
  margin-right: 0;
}

.card h3 {
  color: #2c3e50;
}

.username {
  color: #48df84;
}

/* Course Progress */
.dashboard-courses .course-item {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.dashboard-courses h2, .dashboard-activity h2, .dashboard-info h2 {
  color: #2c3e50;
  font-size: 1.5rem;
}

.progress-bar {
  background-color: #ecf0f1;
  height: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.progress {
  background-color: #3498db;
  height: 100%;
  border-radius: 5px;
}

/* Recent Activity */
.dashboard-activity ul {
  list-style: none;
  padding: 0;
}

.dashboard-activity ul li {
  margin-bottom: 10px;
  font-size: 1rem;
}

.dashboard-activity ul li b {
  color: #2980b9;
}

/* User Info */
.dashboard-info .info-list {
  list-style: none;
  padding: 0;
}

.dashboard-info .info-list li {
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.dashboard-info .info-list li b {
  color: #2c3e50;
}

/* Responsive Design */
@media (max-width: 768px) {  
  .dashboard-container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    margin-bottom: 20px;
  }

  .dashboard-main {
    margin-left: 0;
  }

  .card {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .dashboard-cards {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {

  .sidebar-nav ul {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    overflow-x: auto;
    white-space: nowrap;
    scroll-behavior: auto;
    scrollbar-width: none;
  }

  .dashboard-cards {
    flex-direction: column;
  }

  .card {
    margin-right: 0;
    margin-bottom: 20px;
  }
  
}

@media screen and (max-width: 400px) {
  .card {
    padding: 10px;
  }
  
}