.admin-contacts {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.admin-contacts h1 {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 2rem;
}

.search-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.search-bar input {
  width: 100%;
  max-width: 400px;
  padding: 0.8rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.contacts-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #f9f9f9;
}

.contacts-table th,
.contacts-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.contacts-table th {
  background-color: var(--ifm-dark-bg-color);
  color: white;
  text-transform: uppercase;
}

.contacts-table td {
  font-size: 0.95rem;
  color: #333;
}

.btn-delete {
  background-color: #e74c3c;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-delete:hover {
  background-color: #c0392b;
}

@media (max-width: 768px) {
  .admin-contacts {
    padding: 1rem;
  }

  .contacts-table th,
  .contacts-table td {
    padding: 0.75rem;
  }

  .btn-delete {
    padding: 0.4rem 0.8rem;
  }
}

@media (max-width: 576px) {
  .admin-contacts {
    padding: 1rem;
  }

  .contacts-table th,
  .contacts-table td {
    padding: 0.6rem;
    font-size: 0.9rem;
  }

  .search-bar input {
    width: 100%;
    max-width: 300px;
  }
}