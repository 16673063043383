.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.113), rgba(4, 3, 53, 0.846)),
    url("../../../public/img/hero-bg.jpg");
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
  opacity: 0.9;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1rem;
}

section h2 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #f0f0f5;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #f0f0f5;
  line-height: 1.6;
}

.cta-btn {
  background-color: #ffdd57;
  color: #333;
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-btn:hover {
  background-color: #ffcc00;
}
