* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --ifm-bg-color: rgb(246, 241, 246);
  --ifm-text-color: #333;
  --ifm-text-color-light: #eee;
  --ifm-btn-bg-color: #ffdd57c4;
  --ifm-btn-text-color: #333;
  --ifm-btn-hover-bg-color: #ffdd57;
  --ifm-card-bg-color: #0a3d4d;
  --ifm-footer-bg-color: rgb(50, 7, 48);
  --ifm-link-color: #e2dced;
  --ifm-link-hover-color: #ffdd57;
  --ifm-dark-bg-color: rgba(50, 7, 48, 0.809);
  --ifm-imp-text: #48df84;
  --spinner-color: #ffdd57;  
  --border-color: rgba(255, 255, 255, 0.1);
  --box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

body {
  font-family: "Poppins", sans-serif;
  background-color: var(--ifm-bg-color);
  color: var(--ifm-text-color);
}

/* main {
  margin-top: 50px;
  padding: 20px;
} */