.courses-section {
  padding: 4rem 2rem;
  background-color: #f7f8fa;
  text-align: center;
}

.section-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.section-description {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 3rem;
}

.courses-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.course-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.course-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
}

.course-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 3px solid #ffdd57;
}

.course-content {
  padding: 1.5rem;
  text-align: left;
}

.course-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.course-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
}

.course-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #ffdd57;
  color: #333;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.course-button:hover {
  background-color: #ffcc29;
}

@media (max-width: 768px) {
  .course-image {
    height: 180px;
  }

  .course-title {
    font-size: 1.4rem;
  }

  .course-description {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .course-image {
    height: 150px;
  }

  .course-title {
    font-size: 1.2rem;
  }

  .course-description {
    font-size: 0.85rem;
  }

  .course-button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}