.services-page {
  padding: 2rem;
  background-color: #f8f8f8;
}

.services-header {
  text-align: center;
  margin-bottom: 3rem;
}

.services-header h1 {
  font-size: 3rem;
  color: #333;
}

.services-header p {
  font-size: 1.2rem;
  color: #666;
  max-width: 600px;
  margin: 5px auto;
}

.services-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.loading .loader {
  background-color: var(--ifm-card-bg-color);
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 50px;
  color: var(--ifm-text-color-light);
}

.loading .loader .circle {
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.service-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
}

.service-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  margin-bottom: 1rem;
}

.service-details {
  padding: 2px;
}

.service-details h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: initial;
}

.service-details p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
  text-align: initial;
}

.service-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.service-info span {
  font-size: 0.9rem;
  font-weight: bold;
  color: #3498db;
}

.learn-more-btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: var(--ifm-btn-bg-color);
  color: var(--ifm-text-color);
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
  margin-right: auto;
}

.learn-more-btn:hover {
  background-color: var(--ifm-btn-hover-bg-color);
}

@media (max-width: 768px) {
  .services-header h1 {
    font-size: 2.5rem;
  }

  .services-header p {
    font-size: 1rem;
  }
}
