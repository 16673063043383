.footer {
  padding: 40px 0;
  text-align: left;
  background-color: var(--ifm-footer-bg-color);
  color: var(--ifm-text-color-light);
  position: relative;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-brand {
  font-size: 24px;
  font-weight: 400;
  padding: 0 30px 0 5px;
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-title {
  font-size: 24px;
  font-weight: bold;
}

.footer-description {
  margin: 10px 0;
  font-size: 14px;
  line-height: 1.6;
}

.social-links {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.social-link {
  color: #f4f4f4;
  margin-right: 15px;
  transition: color 0.3s ease;
}

.social-link:hover {
  color: #4a90e2;
}

.social-icon {
  width: 24px;
  height: 24px;
}

.footer-links {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-column {
  max-width: 100%;
}

.footer-heading {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-link {
  color: var(--ifm-text-color-light);
  text-decoration: none;
  transition: color 0.3s ease;
  line-height: 1.6;
}

.footer-link:hover {
  color: #4a90e2;
}

.footer-bottom {
  padding: 10px 0;
  text-align: center;
}

.footer-bottom-content {
  max-width: 1200px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .footer-content {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .footer-links {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .footer-column {
    max-width: 100%;
  }
}
